import ApiUrls from "./api/api.urls"
import ApiBase from "./api/api.base"

export default {

  files: {
    getAccesses(linkId) {
      return ApiBase.get(`${ApiUrls.Files.BasePreviewer}/${linkId}/Accesses`);
    },
    getFile(linkId, config) {
      return ApiBase.get(`${ApiUrls.Files.BasePreviewer}/${linkId}`, config);
    },
    postGetFile(linkId, body, config) {
      return ApiBase.post(`${ApiUrls.Files.BasePreviewer}/${linkId}`, body, config);
    },
    getFileOneTime(oneTimeId, config) {
      return ApiBase.get(`${ApiUrls.Files.BaseShared}/${oneTimeId}/Temporary`, config);
    },
  },

  account: {
    login(params) {
      return ApiUrls.Account.LoginADFS + params;
    },
    logout(params) {
      return ApiUrls.Account.LogoutADFS + params;
    },
    postGetToken(body, config) {
      return ApiBase.post(ApiUrls.Account.GetToken, body, config);
    },
    getCurrentUser() {
      return ApiBase.get(`${ApiUrls.Account.GetCurrentUser}`);
    }
  }

}