let mixin = {
  methods: {
    setBaseState(name) {
      this.$refs[name].setBaseState();
    },

    setNoDataState(name) {
      this.$refs[name].setNoDataState();
    },

    setErrorState(name) {
      this.$refs[name].setErrorState();
    },

    setEmptyState(name) {
      this.$refs[name].setEmptyState();
    },

    setLoadState(name) {
      this.$refs[name].setLoadState();
    }
  }
};

export default mixin;