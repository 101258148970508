<template>
  <div>
    <v-container v-if="state === BASE_STATE" class="full-container">
      <slot></slot>
    </v-container>
    <v-container v-else-if="state === NODATA_STATE" class="full-container">
      <slot name="nodata">
        <div>
          <h2>У файла нет правил доступа</h2>
        </div>
      </slot>
    </v-container>
    <v-container v-else-if="state === ERROR_STATE" class="full-container">
      <slot name="error">
        <div>
          <h2>Ошибка при получении данных</h2>
          <p>
            Отправьте разработчику текст ошибки из консоли или Попробуйте позже
          </p>
        </div>
      </slot>
    </v-container>
    <v-container v-else-if="state == LOAD_STATE" class="full-container">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-container>
    <v-container v-else class="full-container"> </v-container>
  </div>
</template>

<script>
const BASE_STATE = 0;
const NODATA_STATE = 1;
const ERROR_STATE = 2;
const EMPTY_STATE = 3;
const LOAD_STATE = 4;

export default {
  data() {
    return {
      state: BASE_STATE,
      BASE_STATE: BASE_STATE,
      NODATA_STATE: NODATA_STATE,
      ERROR_STATE: ERROR_STATE,
      EMPTY_STATE: EMPTY_STATE,
      LOAD_STATE: LOAD_STATE,
    };
  },

  methods: {
    setBaseState() {
      this.state = BASE_STATE;
    },

    setNoDataState() {
      this.state = NODATA_STATE;
    },

    setErrorState() {
      this.state = ERROR_STATE;
    },

    setEmptyState() {
      this.state = EMPTY_STATE;
    },

    setLoadState() {
      this.state = LOAD_STATE;
    },
  },
};
</script>
