<template>
  <div>
    <v-divider v-if="divider" class="ml-12" />

    <v-list-item>
      <v-list-item-icon class="mr-3">
        <v-icon>mdi-account-circle</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>С помощью учетной записи</v-list-item-title>
        <v-list-item-subtitle v-if="isAuthorized">{{
          email
        }}</v-list-item-subtitle>
        <v-list-item-subtitle v-else>Вы не авторизованы</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          v-if="isAuthorized"
          color="primary"
          :disabled="loading"
          :loading="loading"
          @click="onButtonClicked"
        >
          Выйти
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "ad-list-item",

  props: {
    item: Object,
    index: {
      type: Number,
      default: 0,
    },
    divider: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    email() {
      return this.$store.getters.EMAIL;
    },
    isAuthorized() {
      return this.$store.getters.IS_AUTHORIZED;
    },
  },

  methods: {
    onButtonClicked() {
      this.$emit("click", this.index, true);
    },
  },
};
</script>
