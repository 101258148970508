import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import Access from "../views/Access.vue"
import OneTime from "../views/OneTime.vue"
import OfficeViewer from "../views/OfficeViewer.vue";
import PdfViewer from "../views/PdfViewer.vue";
import ZipViewer from "../views/ZipViewer.vue";
import ImageViewer from "../views/ImageViewer.vue";
import NotViewer from "../views/NotViewer.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/:linkId",
    component: Access,
  },
  {
    path: "/onetime/:oneTimeId",
    component: OneTime,
  }, 
  {
    path: "/preview/office",
    name: "officeviewer",
    component: OfficeViewer,
    props: true,
    meta: {
      title: "Office Preview",
    },
  },
  {
    path: "/preview/pdf",
    name: "pdfviewer",
    component: PdfViewer,
    props: true,
    meta: {
      title: "Pdf Preview",
    },
  },
  {
    path: "/preview/zip",
    name: "zipviewer",
    component: ZipViewer,
    props: true,
    meta: {
      title: "Zip Preview",
    },
  },
  {
    path: "/preview/image",
    name: "imageviewer",
    component: ImageViewer,
    props: true,
    meta: {
      title: "Image Preview",
    },
  },
  {
    path: "/preview/not",
    name: "notviewer",
    component: NotViewer,
    props: true,
    meta: {
      title: "Not Preview",
    },
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// eslint-disable-next-line
router.afterEach((to, from) => {
  Vue.nextTick(
    () => (document.title = to.meta.title || "Irkutsk Oil Previewer")
  );
});

export default router;
