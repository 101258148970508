<template>
  <v-container class="access-container">
    <div v-if="loading || textError">
      <h3 v-if="loading" class="">
        Файл загружается, извините за ожидание
      </h3>
      <h3 v-if="textError" class="">{{ textError }}</h3>
    </div>
    <div v-else>
      <h2 class="mb-2">Данный файл не поддерживает предпросмотр</h2>
      <h4 class="mb-16">Вы можете его скачать</h4>
      <v-btn
        v-if="file"
        color="primary"
        :disabled="loading"
        :loading="loading"
        @click="onButtonClicked"
      >
        Скачать
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import "@/assets/styles/not-viewer.css";

import ApiBase from "@/services/api/api.base";
import ApiService from "@/services/api";

export default {
  props: {
    file: Blob,
    filename: String,
    allowDownload: Boolean,
    linkId: String,
    checkRuleBody: Object,
  },

  data() {
    return {
      loading: false,
      textError: "",
    };
  },

  mounted() {
    if (this.file == undefined) {
      this.getFile();
    } 
  },

  methods: {
    onButtonClicked() {
      this.loading = true;
      ApiBase.download(this.file, this.filename).then(
        () => (this.loading = false)
      );
    },

    getFile() {
      this.loading = true;
      this.linkId = JSON.parse(sessionStorage.getItem("paramsStore")).linkId;
      this.checkRuleBody = {
        id: JSON.parse(sessionStorage.getItem("paramsStore")).checkRuleBody.id,
        pinCode: JSON.parse(sessionStorage.getItem("paramsStore")).checkRuleBody
          .pinCode,
      };
      if (this.checkRuleBody.id) {
        ApiService.files
          .postGetFile(this.linkId, this.checkRuleBody, {
            responseType: "blob",
          })
          .then((response) => {
            this.file = response.data;
            this.filename = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(";")[0];
            this.allowDownload =
              response.headers["allowdownload"] == "True" ? true : false;
            this.loading = false;
          })
          .catch((error) => {
            if (error.response.data) {
              error.response.data.text().then((res) => {
                this.textError = JSON.parse(res).error;
              });
            }
            this.loading = false;
          });
      } else {
        ApiService.files
          .getFile(this.linkId, {
            responseType: "blob",
          })
          .then((response) => {
            this.file = response.data;
            this.filename = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(";")[0];
            this.allowDownload =
              response.headers["allowdownload"] == "True" ? true : false;
            this.loading = false;
          })
          .catch((error) => {
            if (error.response.data) {
              error.response.data.text().then((res) => {
                this.textError = JSON.parse(res).error;
              });
            }
            this.loading = false;
          });
      }
    },
  },
};
</script>
