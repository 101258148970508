import UiConsts from '@/store/consts/ui.consts'
const PrettyBytes = require('pretty-bytes');

export default {

  fileIcon: function(extension) {
    if (!extension)
      return "mdi-file";

    let icon = UiConsts.fileIcons.find(i => i.key == extension);
    if (!icon)
      return "mdi-file";
        
    return icon.value;    
  },

  fileIconColor: function(extension) {
    if (!extension)
      return "#9e9e9e";

    let icon = UiConsts.fileIconColors.find(i => i.key == extension);
    if (!icon)
      return "#9e9e9e";
        
    return icon.value;    
  },

  bytes: function(bytes) {
    return PrettyBytes(bytes);
  }
}