import store from "../store/index";
import { INIT_TOKEN, INIT_CLEAR, INIT_ACCOUNT } from "../store/actions";

import ApiService from "@/services/api";

const LOGIN_REDIRECT_URI = process.env.VUE_APP_LOGIN_REDIRECT_URI;
const LOGOUT_REDIRECT_URI = process.env.VUE_APP_LOGOUT_REDIRECT_URI;

const CLIENT_ID = process.env.VUE_APP_CLIENT_ID;

//Авторизация для получения токена
const login = function () {
  localStorage.setItem("linkId", document.location.pathname);
  try {
    const authorityParams = {
      response_type: "id_token token",
      client_id: CLIENT_ID,
      response_mode: "fragment",
      redirect_uri: LOGIN_REDIRECT_URI,
      scope: "openid",
      nonce: "ffgv_ekdkkvm22_fkro",
    };

    const authoritySearchParams = new URLSearchParams(authorityParams);
    const authorityUrl = ApiService.account.login(authoritySearchParams);

    //Переход на авторизацию
    document.location = authorityUrl;
  } catch (error) {
    console.log(error);
  }
};

const logout = function () {
  localStorage.setItem("linkId", document.location.pathname);
  //Проверка на токен, если его нет, то не производить выход
  if (!store.getters.ACCESS_TOKEN) {
    return;
  }
  
  const logoutParams = {
    id_token_hint: store.getters.ID_TOKEN,
    post_logout_redirect_uri: LOGOUT_REDIRECT_URI,
  };

  let logoutSearchParams = new URLSearchParams(logoutParams);
  let logoutUrl = ApiService.account.logout(logoutSearchParams);
  
  //Переход на выход
  document.location = logoutUrl;

  store.dispatch(INIT_CLEAR);
};

//Инициализация токена и инициализация аккаунта
const initTokenAndCurrentUser = async function () {
  if (!store.getters.ACCESS_TOKEN && !store.getters.ID_TOKEN) {
    await store.dispatch(INIT_TOKEN);
  }
  await store.dispatch(INIT_ACCOUNT);
};

//Получение токена
const getToken = function () {
  if (!store.getters.ACCESS_TOKEN) {
    const urlHash = window.location.hash;
    if (urlHash) {
      const urlSearchParams = urlHash.replace("#", "?");
      const urlParams = new URLSearchParams(urlSearchParams);
      const dataTokenResponse = Object.fromEntries(urlParams.entries());

      return dataTokenResponse;
    }
    return false;
  }
};

//Получение данных о пользователе
const getCurrentUser = function () {
  if (store.getters.ACCESS_TOKEN && !store.getters.isInitialized) {
    return ApiService.account.getCurrentUser().then((response) => {
      return response.data;
    });
  }
};

export default {
  login,
  logout,
  getToken,
  initTokenAndCurrentUser,
  getCurrentUser,
};
