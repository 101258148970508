<template>
  <div>
    <v-divider v-if="divider" class="ml-12" />

    <v-list-group no-action>
      <template v-slot:activator>
        <v-list-item-icon class="mr-3">
          <v-icon>mdi-lock</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>С помощью пин-кода</v-list-item-title>
          <v-list-item-subtitle>
            Скачивание файла
            {{ item.allowDownload ? "разрешено" : "запрещено" }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>

      <v-list-item class="pl-13" color="transparent" :ripple="false" inactive>
        <v-list-item-content>
          <div class="pin-item">
            <v-text-field
              v-model="pin"
              label="Пин-код"
              outlined
              hide-details
              dense
            />
            <v-btn
              color="primary"
              :disabled="!pin || loading"
              :loading="loading"
              @click="onButtonClicked"
            >
              Далее
            </v-btn>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </div>
</template>

<script>
export default {
  name: "anon-list-item",

  data() {
    return {
      pin: null,
    };
  },

  props: {
    item: Object,
    index: {
      type: Number,
      default: 0,
    },
    divider: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onButtonClicked() {
      this.$emit("click", this.index, false, this.pin);
    },
  },
};
</script>
