import Vue from "vue";
import store from "@/store/index";

const setHeader = async () => {
  if (store.getters.ACCESS_TOKEN) {
    Vue.axios.defaults.headers.common["Authorization"] = store.getters.ACCESS_TOKEN
      ? `Bearer ${store.getters.ACCESS_TOKEN}`
      : null;
  } else {
    let access_token = sessionStorage.getItem("access_token");
    Vue.axios.defaults.headers.common["Authorization"] = access_token
      ? `Bearer ${access_token}`
      : null;
  }
};

const deleteHeader = () => {
  delete Vue.axios.defaults.headers.common["Authorization"];
};

export default {
  async get(url, config = null) {
    await setHeader();
    return Vue.axios.get(url, config);
  },

  async post(url, body = null, config = null) {
    await setHeader();
    return Vue.axios.post(url, body, config);
  },

  async put(url, body) {
    await setHeader();
    return Vue.axios.put(url, body);
  },

  async delete(url) {
    await setHeader();
    return Vue.axios.delete(url);
  },
  //Функция для преобразования Blob в ArrayBuffer
  getBuffer(file) {
    deleteHeader();
    return new Promise((resolve, reject) => {
      let contents;
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = function (e) {
        contents = reader.result;
        resolve(contents);
      };
      reader.onerror = function (e) {
        reject(e);
      };
    });
  },
  //Функция для скачивания файла
  download(file, filename) {
    filename = filename.replace(/["']/g, "");
    deleteHeader();
    return new Promise((resolve) => {
      const anchor = document.createElement("a");
      anchor.setAttribute("download", filename || "");
      const blobUrl = URL.createObjectURL(file);
      anchor.href = blobUrl;
      anchor.click();
      setTimeout(() => {
        URL.revokeObjectURL(blobUrl);
        resolve();
      }, 2000);
    });
  },
  //Функция на преобразования Blob в Base64 для просмотра
  getFileContents(file, type) {
    let blobStore = new Blob([file], {
      type: type,
    });

    return new Promise((resolve, reject) => {
      let contents;
      const reader = new FileReader();
      reader.readAsDataURL(blobStore);
      reader.onloadend = function (e) {
        contents = reader.result;
        resolve(contents);
      };
      reader.onerror = function (e) {
        reject(e);
      };
    });
  },
};
