<template>
  <div class="home-container">
    <div class="login-logo-container">
      <v-img :src="require('@/assets/svg/logo.svg')" contain class="logo" />
      <h3 class="logo-title">
        Облачное файловое хранилище <br />
        Просмотр файла
      </h3>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/home.css";

export default {
  components: {},
};
</script>
