import { SET_DATA, CLEAR_DATA, SET_TOKEN, SET_INIT } from "../mutations";
import { INIT_TOKEN, INIT_CLEAR, INIT_ACCOUNT } from "../actions";

import AuthService from "@/services/authADFS";

import EventBus from "@/main";

const state = {
  isInitialized: false,
  isAuthorized: false,
  access_token: null,
  id_token: null,
  username: null,
  email: null,
  role: null,
};

const getters = {
  IS_INITIALIZED: (state) => state.isInitialized,
  IS_AUTHORIZED: (state) => state.isAuthorized,
  ACCESS_TOKEN: (state) => state.access_token,
  ID_TOKEN: (state) => state.id_token,
  USERNAME: (state) => state.username,
  EMAIL: (state) => state.email,
  ROLE: (state) => state.role,
};

const actions = {
  //Действие на получение Токена и ID Токена
  [INIT_TOKEN]: (context) => {
    if (
      !sessionStorage.getItem("access_token") ||
      !sessionStorage.getItem("id_token")
    ) {
      var tokenADFS = AuthService.getToken();
      if (tokenADFS) {
        context.commit(SET_TOKEN, {
          access_token: tokenADFS.access_token,
          id_token: tokenADFS.id_token,
        });
        sessionStorage.setItem("access_token", tokenADFS.access_token);
        sessionStorage.setItem("id_token", tokenADFS.id_token);
      }
    } else {
      context.commit(SET_TOKEN, {
        access_token: sessionStorage.getItem("access_token"),
        id_token: sessionStorage.getItem("id_token"),
      });
    }
  },
  //Инициализация пользователя
  [INIT_ACCOUNT]: async (context) => {
    let user = await AuthService.getCurrentUser();
    context.commit(SET_DATA, {
      isAuthorized: true,
      username: user.name,
      email: user.email,
      role: user.role,
    });
    context.commit(SET_INIT);
  },
  //Очистка данных после выхода
  [INIT_CLEAR](context) {
    sessionStorage.clear();
    context.commit(CLEAR_DATA);
  },
};

const mutations = {
  [SET_DATA](state, { isAuthorized, username, email, role }) {
    state.isAuthorized = isAuthorized;
    state.username = username;
    state.email = email;
    state.role = role;

    if (EventBus) EventBus.$emit("LOGGED");
  },
  [CLEAR_DATA](state) {
    state.isAuthorized = false;
    state.access_token = null;
    state.id_token = null;
    state.username = null;
    state.email = null;
    state.role = null;
  },
  [SET_INIT](state) {
    state.isInitialized = true;
  },
  [SET_TOKEN](state, { access_token, id_token }) {
    state.access_token = access_token;
    state.id_token = id_token;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
