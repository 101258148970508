import Vue from "vue";
import Vuex from "vuex";

import AccountModule from "@/store/modules/account"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    AccountModule
  }
});
