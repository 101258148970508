import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import store from "./store";
import AuthService from "@/services/authADFS";

import "./assets/styles/variables.css";
import "./assets/styles/basic.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
library.add(faDownload);
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

import VueViewer from "v-viewer";
import "viewerjs/dist/viewer.css";
Vue.use(VueViewer);

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import vuetify from "@/plugins/vuetify";

Vue.config.productionTip = false;

let vue = new Vue({
  router,
  store,
  beforeCreate() {
    //Проверка: есть ли токен
    if (
      AuthService.getToken() ||
      (sessionStorage.getItem("access_token") &&
        sessionStorage.getItem("id_token"))
    ) {
      AuthService.initTokenAndCurrentUser();
    }

    //Переход на страницу с файлом после получения токена
    if (localStorage.getItem("linkId") && router.currentRoute.path == "/") {
      router.push({ path: `${localStorage.getItem("linkId")}` });
      localStorage.removeItem("linkId");
    }
  },
  render: (h) => h(App),

  mounted() {
    document.addEventListener("DOMContentLoaded", function () {
      document.addEventListener("contextmenu", (e) => {
        e.preventDefault();
      });
      document.addEventListener("keydown", (e) => {
        if ((e.ctrlKey && e.shiftKey && e.code === "KeyI") || e.code === "F12")
          preventKey(e);
        else if (e.ctrlKey && e.code === "KeyP") preventKey(e);
        else if (e.ctrlKey && e.code === "KeyS") preventKey(e);
      });
      function preventKey(e) {
        if (e.stopPropagation) e.stopPropagation();
        else if (window.event) window.event.cancelBubble = true;
        e.preventDefault();
        return false;
      }
    });
  },

  vuetify,
}).$mount("#app");

export default vue;
