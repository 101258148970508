<template>
  <div v-if="loading || textError">
    <h3 v-if="loading" class="center-screen">
      Файл загружается, извините за ожидание
    </h3>
    <h3 v-if="textError" class="center-screen">{{ textError }}</h3>
  </div>
  <div v-else>
    <div class="pdf-container">
      <pdf v-for="i in pages" :key="i" :page="i" :src="pdfData" :resize="true">
        <template slot="loading"> loading content here... </template>
      </pdf>
    </div>

    <div v-if="allowDownload" class="pdf-download-button">
      <v-btn class="mx-2" color="primary" fab @click="downloadClicked">
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import "@/assets/styles/pdf-viewer.css";

import pdfvuer from "pdfvuer";
import ApiBase from "@/services/api/api.base";
import ApiService from "@/services/api";

export default {
  props: {
    file: Blob,
    filename: String,
    allowDownload: Boolean,
    linkId: String,
    checkRuleBody: Object,
  },

  data() {
    return {
      pdfData: undefined,
      pages: 0,
      loading: false,
      textError: "",
    };
  },

  mounted() {
    if (this.file != undefined) {
      this.getPdf();
    } else {
      this.getFile();
    }
  },

  methods: {
    getPdf() {
      this.loading = true;
      this.getFileContents().then((response) => {
        this.pdfData = pdfvuer.createLoadingTask(response);
        this.pdfData
          .then((pdf) => (this.pages = pdf.numPages))
          .then(() => (this.loading = false));
      });
    },

    getFileContents() {
      return ApiBase.getFileContents(this.file, "application/pdf");
    },

    downloadClicked() {
      this.loading = true;
      if (this.checkRuleBody.id != undefined) {
        let body = {
          id: this.checkRuleBody.id,
          pinCode: this.checkRuleBody.pinCode,
        };
        ApiService.files
          .postGetFile(this.linkId, body, {
            responseType: "blob",
            params: {
              isOriginal: true,
            },
          })
          .then((response) => {
            this.downloadFile(response);
            this.loading = false;
          });
      } else {
        ApiService.files
          .getFile(this.linkId, {
            responseType: "blob",
            params: {
              isOriginal: true,
            },
          })
          .then((response) => {
            this.downloadFile(response);
            this.loading = false;
          })
          .catch((error) => {
            if (error.response.data) {
              error.response.data.text().then((res) => {
                this.textError = JSON.parse(res).error;
              });
            }
            this.loading = false;
          });
      }
    },

    downloadFile(response) {
      let filename = response.headers["content-disposition"]
        .split("filename=")[1]
        .split(";")[0];
      ApiBase.download(response.data, filename);
    },

    getFile() {
      this.loading = true;
      this.linkId = JSON.parse(sessionStorage.getItem("paramsStore")).linkId;
      this.checkRuleBody = {
        id: JSON.parse(sessionStorage.getItem("paramsStore")).checkRuleBody.id,
        pinCode: JSON.parse(sessionStorage.getItem("paramsStore")).checkRuleBody
          .pinCode,
      };
      if (this.checkRuleBody.id) {
        ApiService.files
          .postGetFile(this.linkId, this.checkRuleBody, {
            responseType: "blob",
          })
          .then((response) => {
            this.file = response.data;
            this.filename = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(";")[0];
            this.allowDownload =
              response.headers["allowdownload"] == "True" ? true : false;
            this.getPdf();
            this.loading = false;
          })
          .catch((error) => {
            if (error.response.data) {
              error.response.data.text().then((res) => {
                this.textError = JSON.parse(res).error;
              });
            }
            this.loading = false;
          });
      } else {
        ApiService.files
          .getFile(this.linkId, {
            responseType: "blob",
          })
          .then((response) => {
            this.file = response.data;
            this.filename = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(";")[0];
            this.allowDownload =
              response.headers["allowdownload"] == "True" ? true : false;
            this.getPdf();
            this.loading = false;
          })
          .catch((error) => {
            if (error.response.data) {
              error.response.data.text().then((res) => {
                this.textError = JSON.parse(res).error;
              });
            }
            this.loading = false;
          });
      }
    },
  },

  components: {
    pdf: pdfvuer,
  },
};
</script>
