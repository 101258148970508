export default {

  fileIcons: [
    { key: "zip", value: "mdi-folder-zip" },

    { key: "xls", value: "mdi-file-excel" },
    { key: "xlsx", value: "mdi-file-excel" },

    { key: "ppt", value: "mdi-file-powerpoint" },
    { key: "pptx", value: "mdi-file-powerpoint" },

    { key: "doc", value: "mdi-file-word" },
    { key: "docx", value: "mdi-file-word" },

    { key: "pdf", value: "mdi-file-pdf" },

    { key: "png", value: "mdi-file-image" },
    { key: "jpg", value: "mdi-file-image" },
    { key: "jpeg", value: "mdi-file-image" },
    { key: "tif", value: "mdi-file-image" },
    { key: "tiff", value: "mdi-file-image" },
    { key: "gif", value: "mdi-file-image" },
  ],

  fileIconColors: [
    { key: "zip", value: "#dec800" },

    { key: "xls", value: "#049469" },
    { key: "xlsx", value: "#049469" },

    { key: "ppt", value: "#cc5704" },
    { key: "pptx", value: "#cc5704" },

    { key: "doc", value: "#075ab3" },
    { key: "docx", value: "#075ab3" },

    { key: "pdf", value: "#c71212" },

    { key: "png", value: "#5999ff" },
    { key: "jpg", value: "#5999ff" },
    { key: "jpeg", value: "#5999ff" },
    { key: "tif", value: "#5999ff" },
    { key: "tiff", value: "#5999ff" },
    { key: "gif", value: "#5999ff" },
  ]

}