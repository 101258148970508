export default class File {

  name = null;
  ext = null;
  children = [];
  date = null;
  size = 0;

  constructor(name, ext = null, date = new Date(), size = 0) {
    this.name = name;
    this.ext = ext;
    this.date = date;
    this.size = size;
  }
}