<template>
  <v-container class="oneTime-container">
    <h3 v-if="loading" class="center-screen">
      Файл загружается, извините за ожидание
    </h3>
    <h3 v-if="textError">{{ textError }}</h3>
  </v-container>
</template>

<script>
import "@/assets/styles/oneTime.css";

import ApiService from "@/services/api";

export default {
  data() {
    this.gettingFile();
    return {
      loading: true,
      textError: "",
    };
  },

  methods: {
    gettingFile() {
      let oneTimeId = this.$route.params.oneTimeId;
      ApiService.files
        .getFileOneTime(oneTimeId, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == "204") {
            this.textError =
              "Ошибка доступа к файлу. Ссылка устарела или некорректна";
          } else {
            this.route(
              {
                format: response.headers["fileformat"],
              },
              response.data
            );
          }
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.data) {
            error.response.data.text().then((res) => {
              this.textError = JSON.parse(res).error;
            });
          }
          this.loading = false;
        });
    },

    route(parameters, file) {
      let params = {
        file: file,
      };
      if (parameters.format == 1 || parameters.format == 2) {
        // PDF (Office and PDF)
        this.$router.push({ name: "pdfviewer", params: params });
      } else if (parameters.format == 3) {
        // ZIP
        this.$router.push({ name: "zipviewer", params: params });
      } else if (parameters.format == 4) {
        // Image
        this.$router.push({ name: "imageviewer", params: params });
      }
    },
  },
};
</script>
