<template>
  <div>
    <h3 v-if="textError" class="center-screen">{{ textError }}</h3>
    <div v-if="file" class="zip-container">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Название</th>
              <th class="text-center">Дата</th>
              <th class="text-center">Размер</th>
            </tr>
          </thead>
        </template>
      </v-simple-table>

      <v-treeview
        v-model="tree"
        :items="files"
        item-key="name"
        open-on-click
        class="zip-treeview"
      >
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="item.ext == null" class="file-icon" color="#dec800">
            {{ open ? "mdi-folder-open" : "mdi-folder" }}
          </v-icon>
          <v-icon v-else class="file-icon" :color="item.ext | fileIconColor">
            {{ item.ext | fileIcon }}
          </v-icon>
        </template>
        <template v-slot:append="{ item }">
          <div v-if="item.ext != null">
            <div class="treeview-date-container">
              <span>{{ item.date.toLocaleString("ru-RU") }}</span>
            </div>
            <div class="treeview-size-container">
              <span>{{ item.size | bytes }}</span>
            </div>
          </div>
        </template>
      </v-treeview>
    </div>

    <div v-if="allowDownload" class="zip-download-button">
      <v-btn class="mx-2" color="primary" fab @click="downloadClicked">
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </div>

    <h3 v-if="loading" class="center-screen">
      Файл загружается, извините за ожидание
    </h3>
  </div>
</template>

<script>
import "@/assets/styles/zip-viewer.css";

import Filters from "@/helpers/ui/filters";
import ApiBase from "@/services/api/api.base";
import ApiService from "@/services/api";

import File from "@/store/objects/File";

export default {
  props: {
    file: Blob,
    filename: String,
    allowDownload: Boolean,
    linkId: String,
    checkRuleBody: Object,
  },

  data() {
    return {
      files: [],
      tree: [],

      loading: false,
      textError: "",
    };
  },

  mounted() {
    if (this.file != undefined) {
      this.getZip();
    } else {
      this.getFile();
    }
  },

  methods: {
    getZip() {
      this.loading = true;
      ApiBase.getBuffer(this.file).then((response) => {
        const JSZip = require("jszip");
        const iconv = require("iconv-lite");
        JSZip.loadAsync(new Uint8Array(response), {
          decodeFileName: (bytes) => iconv.decode(bytes, "cp866"),
        }).then(this.parseZip);
      });
    },

    parseZip(zip) {
      let files = [];

      zip.forEach((element) => {
        let zipFile = zip.files[element];

        if (!zipFile.name || zipFile.name == "") return;

        let segments = zipFile.name.split("/");

        let file = files.find((f) => f.name == segments[0]);
        let tmpFiles = files;

        for (let i = 0; i < segments.length; i++) {
          if (i == segments.length - 1) {
            if (!file) {
              if (segments[i] == null || segments[i] == "") continue;

              let newFile = new File(
                segments[i],
                zipFile.dir ? null : this.getExtension(segments[i]),
                new Date(zipFile.date),
                zipFile._data.uncompressedSize
              );
              tmpFiles.push(newFile);
            } else {
              let newFile = new File(
                segments[i],
                zipFile.dir ? null : this.getExtension(segments[i]),
                new Date(zipFile.date),
                zipFile._data.uncompressedSize
              );
              file.children.push(newFile);
            }
          } else {
            if (!file) {
              let newFile = new File(segments[i]);
              tmpFiles.push(newFile);
              tmpFiles = newFile.children;
            } else {
              tmpFiles = file.children;
            }
          }

          if (i < segments.length - 1)
            file = tmpFiles.find((f) => f.name == segments[i + 1]);
        }
      });

      this.deepSort(files);
      this.files = files;

      this.loading = false;
    },

    deepSort(files) {
      files.forEach((file) => this.deepSort(file.children));
      files.sort(this.filesComparator);
    },

    filesComparator(a, b) {
      if (!(Boolean(a.ext) ^ Boolean(b.ext)))
        return a.name.localeCompare(b.name);
      else if (!a.ext && b.ext) return -1;
      else return 1;
    },

    getExtension(fileName) {
      return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
    },

    downloadClicked() {
      ApiBase.download(this.file, this.filename);
    },

    getFile() {
      this.loading = true;
      this.linkId = JSON.parse(sessionStorage.getItem("paramsStore")).linkId;
      this.checkRuleBody = {
        id: JSON.parse(sessionStorage.getItem("paramsStore")).checkRuleBody.id,
        pinCode: JSON.parse(sessionStorage.getItem("paramsStore")).checkRuleBody
          .pinCode,
      };
      if (this.checkRuleBody.id) {
        ApiService.files
          .postGetFile(this.linkId, this.checkRuleBody, {
            responseType: "blob",
          })
          .then((response) => {
            this.file = response.data;
            this.filename = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(";")[0];
            this.allowDownload =
              response.headers["allowdownload"] == "True" ? true : false;
            this.getZip();
            this.loading = false;
          })
          .catch((error) => {
            if (error.response.data) {
              error.response.data.text().then((res) => {
                this.textError = JSON.parse(res).error;
              });
            }
            this.loading = false;
          });
      } else {
        ApiService.files
          .getFile(this.linkId, {
            responseType: "blob",
          })
          .then((response) => {
            this.file = response.data;
            this.filename = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(";")[0];
            this.allowDownload =
              response.headers["allowdownload"] == "True" ? true : false;
            this.getZip();
            this.loading = false;
          })
          .catch((error) => {
            if (error.response.data) {
              error.response.data.text().then((res) => {
                this.textError = JSON.parse(res).error;
              });
            }
            this.loading = false;
          });
      }
    },
  },

  filters: {
    fileIcon: Filters.fileIcon,
    fileIconColor: Filters.fileIconColor,
    bytes: Filters.bytes,
  },
};
</script>
