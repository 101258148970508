<template>
  <div>
    <v-divider v-if="divider" class="ml-12" />

    <v-list-item two-line>
      <v-list-item-icon class="mr-3">
        <v-icon>mdi-link</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Анонимный доступ</v-list-item-title>
        <v-list-item-subtitle>
          Скачивание файла {{ item.allowDownload ? "разрешено" : "запрещено" }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-progress-circular
          v-show="loading"
          indeterminate
          color="primary"
          size="23"
          :width="2"
          class="mr-8"
        />
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "anon-list-item",

  props: {
    item: Object,
    index: {
      type: Number,
      default: 0,
    },
    divider: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
