const BASE_URL = process.env.VUE_APP_BASE_URL;
const BASE_URL_ADFS = process.env.VUE_APP_ADFS;

export default {
  Files: {
    BasePreviewer: BASE_URL + "/Previewer/Files",
    BaseShared: BASE_URL + "/Files",
  },

  Account: {
    GetToken: BASE_URL_ADFS + "/token",
    LoginADFS: BASE_URL_ADFS + "/authorize?",
    LogoutADFS: BASE_URL_ADFS + "/logout?",
    GetCurrentUser: BASE_URL + "/Previewer/Users/Current"
  },
};
