<!-- Оставлен на всякий случай. Дата измений без этого компонента: Feature 23. Дата 26.01.23  -->
<template>
  <div>
    <iframe
      :src="src"
      :id="allowDownload ? 'office-frame' : 'office-frame-embed'"
    />
  </div>
</template>

<script>
import "@/assets/styles/office-viewer.css";

export default {
  props: {
    url: String,
    allowDownload: Boolean,
  },

  data: function () {
    let src = "";
    if (this.url != undefined)
      src =
        `https://view.officeapps.live.com/op/${
          this.allowDownload ? "view" : "embed"
        }.aspx?src=` + escape(this.url);

    return {
      src: src,
    };
  },
};
</script>
