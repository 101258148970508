<template>
  <div v-if="loading || textError">
    <h3 v-if="loading" class="center-screen">
      Файл загружается, извините за ожидание
    </h3>
    <h3 v-if="textError" class="center-screen">{{ textError }}</h3>
  </div>
  <div v-else class="image-viewer-background"></div>
</template>

<script>
import "@/assets/styles/image-viewer.css";
import ApiBase from "@/services/api/api.base";
import ApiService from "@/services/api";

export default {
  props: {
    file: Blob,
    filename: String,
    allowDownload: Boolean,
    linkId: String,
    checkRuleBody: Object,
  },

  mounted() {
    if (this.file != undefined) {
      this.show();
    } else {
      this.getFile();
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.viewerInstance) this.viewerInstance.destroy();
    next();
  },

  data() {
    return {
      viewerInstance: null,
      textError: "",
      loading: false,
    };
  },

  methods: {
    show() {
      this.getFileContents().then((response) => {
        this.viewerInstance = this.$viewerApi({
          images: [response],
          options: {
            button: false,
            navbar: false,
            title: false,
            tooltip: false,
            fullscreen: true,
            keyboard: false,
            backdrop: false,
            inline: true,
            toolbar: {
              oneToOne: false,
              next: false,
              play: false,
              prev: false,
              zoomIn: true,
              zoomOut: true,
              rotateLeft: true,
              rotateRight: true,
              flipHorizontal: true,
              flipVertical: true,
              reset: true,
              download: {
                show: this.allowDownload,
                click: this.downloadClicked,
              },
            },
          },
        });
      });
    },

    getFileContents() {
      return ApiBase.getFileContents(this.file, "image/png");
    },

    downloadClicked() {
      ApiBase.download(this.file, this.filename);
    },

    getFile() {
      this.loading = true;
      this.linkId = JSON.parse(sessionStorage.getItem("paramsStore")).linkId;
      this.checkRuleBody = {
        id: JSON.parse(sessionStorage.getItem("paramsStore")).checkRuleBody.id,
        pinCode: JSON.parse(sessionStorage.getItem("paramsStore")).checkRuleBody
          .pinCode,
      };
      if (this.checkRuleBody.id) {
        ApiService.files
          .postGetFile(this.linkId, this.checkRuleBody, {
            responseType: "blob",
          })
          .then((response) => {
            this.file = response.data;
            this.filename = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(";")[0];
            this.allowDownload =
              response.headers["allowdownload"] == "True" ? true : false;
            this.show();
            this.loading = false;
          })
          .catch((error) => {
            if (error.response.data) {
              error.response.data.text().then((res) => {
                this.textError = JSON.parse(res).error;
              });
            }
            this.loading = false;
          });
      } else {
        ApiService.files
          .getFile(this.linkId, {
            responseType: "blob",
          })
          .then((response) => {
            this.file = response.data;
            this.filename = response.headers["content-disposition"]
              .split("filename=")[1]
              .split(";")[0];
            this.allowDownload =
              response.headers["allowdownload"] == "True" ? true : false;
            this.show();
            this.loading = false;
          })
          .catch((error) => {
            if (error.response.data) {
              error.response.data.text().then((res) => {
                this.textError = JSON.parse(res).error;
              });
            }
            this.loading = false;
          });
      }
    },
  },
};
</script>
